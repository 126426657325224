<template>
  <v-container fluid>
    <v-card-title>
      추천답변 추가
    </v-card-title>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>작성 멘토</v-list-item-title>
            <MentorInput v-model="mentor" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>제목</v-list-item-title>
            <v-text-field v-model="title" outlined dense hide-details />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>라벨</v-list-item-title>
            <LabelInput v-model="stamp" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>투명라벨</v-list-item-title>
            <v-combobox v-model="selectedInvisibleTags" outlined dense :items="tagInvisibleItems" multiple deletable-chips chips hide-details />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <markdown-editor v-model="req.main" style="background: white" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="$router.push('/reply')">
          취소
        </v-btn>
        <v-btn color="primary" @click="createCoreReply">
          추가하기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor'
import MentorInput from '@/components/input/Mentor.vue'
import LabelInput from '@/components/input/Label.vue'

export default defineComponent({
  components: {
    MarkdownEditor,
    MentorInput,
    LabelInput,
  },

  setup(props, { root }) {
    const state = reactive({
      select: {
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
      },
      stamp: {
        id: null,
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
      },
      req: {
        main: '',
        stampList: [],
        invisibleStampList: [],
        title: null,
        mentorId: null,
      },
      res: {
        select: {
          firstDepth: [],
          secondDepth: [],
          thirdDepth: [],
        },
      },
      selectedInvisibleTags: [],
      tagInvisibleItems: [],
      title: null,
      mentor: null,
    })

    const createCoreReply = async () => {
      try {
        state.req.invisibleStampList = state.selectedInvisibleTags.map((tag) => {
          if (typeof tag == 'object') return tag['text']

          return tag
        })

        state.req.mentorId = Number(state.mentor.id)
        state.req.title = state.title
        state.req.stampList = [Number(state.stamp.id)]
        await root.$store.dispatch('coreReply/createCoreReply', state.req)
        alert('생성완료!')
        state.req = {
          main: null,
          stampList: [],
          invisibleStampList: [],
          mentorId: null,
        }
        state.main = ''
        state.stampList = []
        root.$router.push('/reply')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(async () => {
      try {
        state.tagInvisibleItems = await root.$store.dispatch('stamp/getListInvisibleStamp')
        state.tagInvisibleItems = state.tagInvisibleItems
          .map((e) => ({
            text: e.name,
            value: e.id,
          }))
          .sort((x, y) => x.text.localeCompare(y.text))
      } catch (err) {
        console.error(err)
      }
    })

    return {
      ...toRefs(state),
      createCoreReply,
    }
  },
})
</script>
